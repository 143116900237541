<script>
	import { useMeta } from "vue-meta";
	import Menu from "@/components/Tiger_25_Q1-2/Menu";
	import Alert from "@/components/Alert";

	export default {
		name: "Tiger25Q1-2_home",
		data() {
			return {
				isActiveAlert: false,
			};
		},
		setup() {
			useMeta({
				title: "虎牌啤酒，一起無所畏",
				description: "2025/3/5-4/1至指定通路，購買虎牌全系列啤酒任兩件，登錄發票抽高雄櫻花季周邊",
			});
		},
		components: {
			Menu,
			Alert,
		},
		computed: {},
		watch: {},
		mounted() {},
		methods: {
			checkBrowser() {
				// console.log("checkBrowser")
				let vm = this;
				var useragent = navigator.userAgent;
				var rules = ["WebView", "(iPhone|iPod|iPad)(?!.*Safari\/)", "Android.*(Instagram|Line|FBAN|FBAV)"];
				var regex = new RegExp(`(${rules.join("|")})`, "ig");
				if (Boolean(useragent.match(regex))) {
					vm.isActiveAlert = true;
				} else {
					location.href = "https://heineken-event.webgene.com.tw/auth/redirect/tiger-25-q1-2";
				}
			},
		},
	};
</script>
<template src="./template.pug" lang="pug"></template>
<style src="./style.sass" lang="sass" scoped></style>
